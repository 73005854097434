import React from "react"
import { Typography, Container } from "@material-ui/core"
import Layout from "../components/layout"
import { ServicesDetailCard } from "../components/molecules"
import styled from "styled-components"
import { GridContainer, GridItem } from "../components/Grid"
import servicesData from "../content/services"


const StyledContainer = styled(Container)`
  margin-bottom: 64px;
  margin-top: 160px;
`
const StyledHeading = styled(Typography)`
margin-top: 16px;
`

const Services = () => {
  return (
    <Layout>
      <StyledContainer maxWidth="xl">
        <Typography variant="h5" as="h1">Our Services</Typography>
        <GridContainer>
          {servicesData.services.map((service, index) => {
            return (
              <GridItem xs={12} md={6} lg={4} key={`${service}${index}`}>
                <ServicesDetailCard altText={service.altText} imgIndex={index} height={240}>
                  <StyledHeading variant="h5" gutterBottom>{service.title}</StyledHeading>
                  <Typography variant="body2">{service.desc}</Typography>
                </ServicesDetailCard>
              </GridItem>
            )
          })}
        </GridContainer>
      </StyledContainer>
    </Layout>
  )
}

export default Services
